import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Eye, EyeOff, Check, X } from 'react-feather';

import { Card, CardBody, Button, Input, Label, FormGroup, Alert, Spinner } from 'reactstrap';

import { connect } from 'react-redux';
import Moment from 'react-moment';

import {
  updateUserData,
  updatePassword,
} from '../../../../actions/user';

class Personal extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    roles: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
    admin: {},
    roles: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      roles: {
        teamOwner: 'Team Owner',
      },
      showPassword1: 'password',
      showPassword2: 'password',
      websites: {},
      changes: {},
      passwordChangeActive: false,
    };
  }

  componentDidMount() {
    const { user, isLoggedIn, history } = this.props;

    if (user && Object.keys(user).length > 0) {
      if (!user || !isLoggedIn) {
        history.push('/');
      } else {
      }
    } else {
      history.push('/');
    }
  }

  handleChange = (e) => {
    if (e.target && e.target.name) {
      const { name, value } = e.target;
      if (name) {
        this.setState((prevState) => ({
          changes: {
            ...prevState.changes,
            [name]: value || '',
          },
        }));
      }
    }
  };

  togglePasswordChange = () => {
    this.setState((prevState) => ({
      passwordChangeActive: !prevState.passwordChangeActive,
    }));
  }

  toggleShowPassword = (id) => {
    if (id === 1) {
      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  handleChangePassword = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  savePasswordChange = () => {
    const { updateUserPassword } = this.props;
    const { password, confirmPassword } = this.state;

    if (!confirmPassword || !password) {
      this.setState({ success: null, error: 'Missing password' });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else if (confirmPassword !== password) {
      this.setState({ success: null, error: 'Passwords don’t match' });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      if (password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/)) {
        this.setState({ error: null, buttonPressed: 'password' });

        return updateUserPassword(password)
          .then(() => this.setState({ success: 'Password changed succesfully', error: null, passwordChangeActive: false, password: null, confirmPassword: null, buttonPressed: null }))
          .catch((err) => {
            console.log(err);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            this.setState({ success: null, error: err, buttonPressed: null })
          });
      } else {
        this.setState({ success: null, error: 'Password needs to be at least 8 characters, have at least 1 uppercase, lowercase, number, symbol.', buttonPressed: null });

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }

  startChange = (e, id, value) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      changes: {
        ...prevState.changes,
        [id]: value || '',
      },
      error: null,
      success: null,
    }));
  }

  saveChange = (e, id) => {
    e.stopPropagation();
    const { updateUserValue } = this.props;
    const { changes } = this.state;

    if (id && changes[id]) {
      if (id === 'email' && !changes[id].match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        this.setState({ success: null, error: 'Invalid email format' });

        return null;
      }

      this.setState({ buttonPressed: id });

      updateUserValue(id, changes[id]).then(() => {
        if (id === 'email') {
          this.setState({ success: 'Please validate your new email address by clicking the verification link we’ve sent to you.', error: null, buttonPressed: null });
        } else {
          this.setState({ success: 'Data saved succesfully', error: null, buttonPressed: null });
        }
      }).catch(err => {
        if (err && err.message) {
          this.setState({ success: null, error: err.message, buttonPressed: null });
        } else {
          this.setState({ success: null, error: err, buttonPressed: null });
        }
      });

      this.setState((prevState) => ({
        changes: {
          ...prevState.changes,
          [id]: null,
        },
      }));
    }
  }

  closeChange = (e, id) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      changes: {
        ...prevState.changes,
        [id]: null,
      },
      error: null,
      success: null,
    }));
  }

  render() {
    const { user, roles } = this.props;
    const {
      password,
      confirmPassword,
      showPassword1,
      showPassword2,
      passwordChangeActive,
      changes,
      error,
      success,
      buttonPressed,
    } = this.state;

    console.log('user', user);

    return (
      <div>
        <Card>
          <CardBody>
            <div class="table-responsive basic-information">
              {(!error && !!success) && <Alert color="success">{success}</Alert>}
              {(!!error) && <Alert color="danger">{error}</Alert>}

              <div className="basic-information-row">
                <Label className="basic-information-label" for="name">Name</Label>
                {(changes.name || changes.name === '') ? (
                  <>
                    <div onClick={(e) => e.stopPropagation()} className="basic-information-middle">
                      <Input
                        name="name"
                        id={`website-edit`}
                        value={changes.name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="basic-information-buttons">
                      <Button color="primary" disabled={buttonPressed} className="basic-information-check-button" onClick={(e) => this.saveChange(e, 'name')}>
                        <Check />
                        {buttonPressed === 'name' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                      <Button color="primary" className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'name')}>
                        <X />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                      <div className="basic-information-middle" onClick={(e) => this.startChange(e, 'name', (user.name ? user.name : ((user.firstName || user.lastName) ? (`${user.firstName || ''} ${user.lastName || ''}`) : null)))}>
                      {user.name ? user.name : ((user.firstName || user.lastName) ? (`${user.firstName || ''} ${user.lastName || ''}`) : (''))}
                    </div>
                    <div className="basic-information-buttons">
                      <Button
                        disabled={buttonPressed}
                        color="link"
                        className="basic-information-edit-button"
                        onClick={(e) => this.startChange(e, 'name', (user.name ? user.name : ((user.firstName || user.lastName) ? (`${user.firstName || ''} ${user.lastName || ''}`) : null)))}
                      >
                        {buttonPressed === 'name' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                        <span>
                          {/* {'\uD83D\uDD89'} */}
                          <text id="gText_11081308229940" name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="basic-information-row">
                  <Label className="basic-information-label" for="lastName">Last name</Label>
                  {(changes.lastName || changes.lastName === '') ? (
                    <>
                      <div onClick={(e) => e.stopPropagation()} style={{ display: 'inline-block', width: '40%' }}>
                        <Input
                          name="lastName"
                          id={`website-edit`}
                          value={changes.lastName}
                          onChange={this.handleChange}
                        />
                      </div>
                    <Button color="primary" disabled={buttonPressed} className="basic-information-edit-button" onClick={(e) => this.saveChange(e, 'lastName')}>
                        <Check/>
                        {buttonPressed === 'lastName' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ):(null)}
                      </Button>
                    <Button color="primary" className="basic-information-edit-button" onClick={(e) => this.closeChange(e, 'lastName')}>
                        <X/>
                      </Button>
                    </>
                  ):(
                    <>
                      <div style={{ display: 'inline-block', width: '40%' }}>
                        {user.lastName}
                      </div>
                      <Button disabled={buttonPressed} color="link" className="basic-information-edit-button" onClick={(e) => this.startChange(e, 'lastName', user.lastName)}>
                      {buttonPressed === 'lastName' ? (
                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                      ):(null)}
                      <span>
                        Edit
                      </span>
                      </Button>
                    </>
                  )}
                </div> */}

              <div className="basic-information-row">
                <Label className="basic-information-label" for="roleInCompany">Email</Label>
                {(changes.email || changes.email === '') ? (
                  <>
                    <div onClick={(e) => e.stopPropagation()} className="basic-information-middle">
                      <Input
                        name="email"
                        type="email"
                        id={`website-edit`}
                        value={changes.email}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="basic-information-buttons">
                      <Button color="primary" disabled={buttonPressed} className="basic-information-check-button" onClick={(e) => this.saveChange(e, 'email')}>
                        <Check />
                        {buttonPressed === 'email' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                      <Button color="primary" className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'email')}>
                        <X />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                      <div className="basic-information-middle" onClick={(e) => this.startChange(e, 'email', user.email)}>
                      {user.email}
                    </div>
                    <div className="basic-information-buttons">
                      <Button disabled={buttonPressed} color="link" className="basic-information-edit-button" onClick={(e) => this.startChange(e, 'email', user.email)}>
                        {buttonPressed === 'email' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                        <span>
                          {/* {'\uD83D\uDD89'} */}
                          <text id="gText_11081308229940" name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div className="basic-information-row">
                <Label className="basic-information-label" for="password">Password</Label>
                <div className="basic-information-middle" />
                <div className="basic-information-buttons">
                  <Button disabled={buttonPressed} color="link" id="password" style={{ paddingLeft: 0, display: 'block' }} className="basic-information-edit-button" onClick={this.togglePasswordChange}>
                    {buttonPressed === 'password' ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                    ) : (null)}
                    <span>
                      {/* {'\uD83D\uDD89'} */}
                      <text id="gText_11081308229940" name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                    </span>
                  </Button>
                </div>

              </div>

              {passwordChangeActive ? (
                <div className="basic-information-row">
                  <FormGroup style={{ display: 'block', width: '31%', marginRight: 15, position: 'relative' }}>
                    <Label for="password">Password<div className="mandatory">*</div></Label>
                    <Input
                      type={showPassword1}
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      value={password}
                      onChange={this.handleChangePassword}
                    />
                    {showPassword1 === 'input' ? (
                      <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(1)} />
                    ) : (
                      <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(1)} />
                    )}

                  </FormGroup>
                  <FormGroup style={{ display: 'block', width: '31%', marginRight: 15, position: 'relative' }}>
                    <Label for="confirmPassword">Confirm Password<div className="mandatory">*</div></Label>
                    <Input
                      type={showPassword2}
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="••••••••"
                      value={confirmPassword}
                      onChange={this.handleChangePassword}
                    />
                    {showPassword2 === 'input' ? (
                      <Eye style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(2)} />
                    ) : (
                      <EyeOff style={{ position: 'absolute', right: 0, bottom: 0, margin: '0.375rem 0.75rem' }} onClick={() => this.toggleShowPassword(2)} />
                    )}
                  </FormGroup>
                  {/* <Button disabled={buttonPressed} style={{ maxHeight: 40, display: 'inline-block', marginTop: 31 }} color="link" className="basic-information-edit-button" onClick={this.savePasswordChange}>
                      Save
                      {buttonPressed === 'password' ? (
                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                      ):(null)}
                  </Button> */}
                  <div className="basic-information-buttons basic-information-buttons-password" >

                    <Button color="primary" className="basic-information-x-button" onClick={this.togglePasswordChange}>
                      <X />
                    </Button>
                    <Button color="primary" disabled={buttonPressed} className="basic-information-check-button" onClick={this.savePasswordChange}>
                      <Check />
                      {buttonPressed === 'phoneNumber' ? (
                        <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                      ) : (null)}
                    </Button>
                  </div>
                </div>
              ) : (null)}
              <div className="basic-information-row">
                <Label className="basic-information-label" for="roleInCompany">Phone number</Label>
                {(changes.phoneNumber || changes.phoneNumber === '') ? (
                  <>
                    <div onClick={(e) => e.stopPropagation()} className="basic-information-middle">
                      <Input
                        name="phoneNumber"
                        id={`website-edit`}
                        value={changes.phoneNumber}
                        placeholder="07777 000 000"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="basic-information-buttons">
                      <Button color="primary" disabled={buttonPressed} className="basic-information-check-button" onClick={(e) => this.saveChange(e, 'phoneNumber')}>
                        <Check />
                        {buttonPressed === 'phoneNumber' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                      <Button color="primary" className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'phoneNumber')}>
                        <X />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                      <div className="basic-information-middle" onClick={(e) => this.startChange(e, 'phoneNumber', user.phoneNumber)}>
                      {user.phoneNumber ? user.phoneNumber : <div className="basic-information-placeholder"> 07777 000 000 </div>}
                    </div>
                    <div className="basic-information-buttons">
                      <Button disabled={buttonPressed} color="link" className="basic-information-edit-button" onClick={(e) => this.startChange(e, 'phoneNumber', user.phoneNumber)}>
                        {buttonPressed === 'phoneNumber' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                        <span>
                          {/* {'\uD83D\uDD89'} */}
                          <text id="gText_11081308229940" name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <div className="basic-information-row">
                <Label className="basic-information-label" for="roleInCompany">Role in company</Label>
                {(changes.roleInCompany || changes.roleInCompany === '') ? (
                  <>
                    <div onClick={(e) => e.stopPropagation()} className="basic-information-middle">
                      <Input name="roleInCompany" id="roleInCompany" type="select" className="form-control" value={changes.roleInCompany} onChange={this.handleChange}>
                        <option value={'owner'}>Owner</option>
                        <option value={'CEO'}>CEO</option>
                        <option value={'MD'}>MD</option>
                        <option value={'director'}>Director</option>
                        <option value={'manager'}>Manager</option>
                        <option value={'employee'}>Employee</option>
                        <option value={'agency/agent'}>Agency/Agent</option>
                        <option value={'intern'}>Intern</option>
                        <option value={'other'}>Other</option>
                      </Input>
                    </div>
                    <div className="basic-information-buttons">
                      <Button color="primary" disabled={buttonPressed} className="basic-information-check-button" onClick={(e) => this.saveChange(e, 'roleInCompany')}>
                        <Check />
                        {buttonPressed === 'roleInCompany' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                      </Button>
                      <Button color="primary" className="basic-information-x-button" onClick={(e) => this.closeChange(e, 'roleInCompany')}>
                        <X />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                      <div className="basic-information-middle" style={{ textTransform: 'capitalize' }} onClick={(e) => this.startChange(e, 'roleInCompany', user.roleInCompany)}>
                      {user.roleInCompany}
                    </div>
                    <div className="basic-information-buttons">
                      <Button disabled={buttonPressed} color="link" className="basic-information-edit-button" onClick={(e) => this.startChange(e, 'roleInCompany', user.roleInCompany)}>
                        {buttonPressed === 'roleInCompany' ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem' }}> {''} </Spinner>
                        ) : (null)}
                        <span>
                          {/* {'\uD83D\uDD89'} */}
                          <text id="gText_11081308229940" name="-1" font="Arial" rotate="0" horizAnchor="middle" vertAnchor="middle" width="1" stroke="0x000000">&#x1F589;</text>
                        </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <div className="basic-information-row">
                <Label className="basic-information-label" for="role">Permission level</Label>
                <div className="basic-information-middle">
                  {(user.role === 'teamOwner') ? 'Main Company Account' : (roles[user.role] ? roles[user.role].name : null)}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  roles: state.users.companiesRoles || {},
  //  roles: state.admin.roles || {},
});

const mapDispatchToProps = {
  updateUserValue: updateUserData,
  updateUserPassword: updatePassword,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Personal));
